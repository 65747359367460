.bannerSplitUs{
    background: url("../../assets/splitUs/patternBanner.png"), #ffda31 ;
    height: 400px;
    padding-top: 80px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    text-align: center;
    justify-content: center;
}

.bannerSplitUs div:nth-child(1){
    width: 50%;
    height: 100%;
}

.bannerSplitUs div:nth-child(2){
    width: 40%;
    height: 100%;
    margin-block: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dataAppSplitUs{
    background-color: #282828;
    color: #ffda31;
    padding-left: 11%;
    padding-right: 45%;
    padding-block: 5%;
    position: relative;
    
}

.mobileSplitUs{
    position: absolute;
    width: 300px;
    top: 75px;
    right: 40px;
}

.buttonSplitUs{
    background-color: #ffda31;
    color: #000;
    padding-inline: 17px;
    transition: all 500ms;
}

.buttonSplitUs:hover{
    border: 3px solid #ffda31;
    color: #ffda31;
    background-color: transparent;
}

.marcasSplitUs{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 20px 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
    
}

.marcasSplitUs img{
    width: 100%;

}

.toolsSplitUs{
    padding-left: 11%;
    padding-right: 45%;
    padding-block-start: 2%;
    background-color: #f2f2f2;
    max-width: 1000px;
    padding-bottom: 150px;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 90%, 0 65%);
    clip-path: polygon(0 0, 100% 0%, 100% 90%, 0 65%);

}


@media screen and (min-width: 1400px) {
    .bannerSplitUs{
        padding-inline: 25%;
    }
    .bannerSplitUs div:nth-child(1){
        width: 50%;
    }
    .bannerSplitUs div:nth-child(2){
        width: 50%;
    }
}

@media screen and (max-width: 769px) {
    .bannerSplitUs{
        height: 300px;
        justify-content: space-between;
    }
    .dataAppSplitUs{
        text-align: center;
        padding-inline: 10%;
        padding-bottom: 200px;
    }
    .toolsSplitUs{
        text-align: center;
        padding-inline: 15%;
        padding-top: 350px;
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 90%, 0 78%);
        clip-path: polygon(0 0, 100% 0%, 100% 90%, 0 78%);
    }
    .mobileSplitUs{
        position: absolute;
        width: 300px;
        top: 275px;
        left: 30%;
        right: 20%;
    }
}

@media screen and (max-width: 600px) {
    .mobileSplitUs{
        top: 285px;
        left: 25%;
        right: 20%;
    }
    .bannerSplitUs{
        height: 200px;
    }
}

@media screen and (max-width: 480px) {
    .mobileSplitUs{
        top: 310px;
        left: 20%;
        right: 20%;
    }
}

@media screen and (max-width: 416px) {
    .mobileSplitUs{
        top: 320px;
        left: 15%;
        right: 20%;
    }
}

@media screen and (max-width: 376px) {
    .mobileSplitUs{
        width: 250px;
        top: 370px;
        left: 17%;
        right: 20%;
    }
}

.logoHere{
    padding-block: 10px;
    color: #CCC;
    text-transform: uppercase;
    font-weight: bold;
}

.logoHere p{
    
    text-transform: uppercase;
    font-weight: bold;
}

.lineaLogo{
    height: 2px;
    width: 100%;
    background-color: #CCC;
}