@media screen and (max-width: 600px) {
    .textSizeMap h3{
        font-size: 14px;
    } 
    .textSizeMap p{
        font-size: 12px;
    }
    .iconsMap{
        height: 35px;
    } 
}