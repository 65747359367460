.bannerServices{
    display: flex;
    flex-direction: row;
    padding-top: 80px;
    padding-inline: 5%;
    justify-content: space-between;
    align-items: center;
    gap: 15%;
    max-width: 1200px;
    margin-inline: auto;
}

.textServicesSide{
    width: 50%;
}

.imageServicesSide{
    position: relative;
    width: 50%;
    padding-block: 50px;
}

.imageServicesSide img{
    width: 90%;
    height: 100%;
    box-shadow: 37px -27px 0px 0px rgba(255,102,51,1);
    -webkit-box-shadow: 37px -27px 0px 0px rgba(255,102,51,1);
    -moz-box-shadow: 37px -27px 0px 0px rgba(255,102,51,1);
}

.servicesDescriptions{
    width: 100%;
    background-color: #FF6633;
    padding-block: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.containerServicesDescriptions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 50px;
    width: 95%;
    margin-inline: auto;
    max-width: 1600px;
}

.cardServies{
    width: 33%;
    background-color: white;
    padding: 20px;
    text-align: center;
    box-shadow: 12px 14px 0px 0px rgba(0,0,0,1);
    -webkit-box-shadow: 12px 14px 0px 0px rgba(0,0,0,1);
    -moz-box-shadow: 12px 14px 0px 0px rgba(0,0,0,1);
}

.cardServies h3{
    color: #FF6633;
}

.cardServies img{
    width: 20%;
}

.ecosystem{
    padding-block: 40px;
    text-align: center;
    padding-inline: 5%;
}

.ecosystem h2, .ecosystem p{
    color: #FF6633;
}

.ecosystem h2{
    font-weight: 500;
    font-size: 30px;
}

.ecosystem p{
    width: 70%;
    margin-inline: auto;
}

.ecosystem img{
    margin-top: 50px;
    width: 80%;
    max-width: 1000px;
}

.technologies{
    width: 100%;
    background-color: #FF6633;
    text-align: center;
    color: white;
    padding-block: 40px;
   
}

.technologiesContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 40px;
    row-gap: 70px;
    margin-inline: 10%;
}

.technologiesCard{
    width: 29%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 10px 10px 1px 0px rgba(0,0,0,1);
    -webkit-box-shadow: 10px 10px 1px 0px rgba(0,0,0,1);
    -moz-box-shadow: 10px 10px 1px 0px rgba(0,0,0,1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 40px;
}


@media screen and (max-width: 768px) {
    .containerServicesDescriptions{
        flex-wrap: wrap;
    }
    .cardServies{
        width: 36%;
    }
    .technologiesCard{
        width: 43%;
    }
    .technologiesContainer{
        margin-inline: 4%;
    }
    .bannerServices{
        gap: 5%;
    }
}

@media screen and (max-width: 540px) {
    .containerServicesDescriptions{
        flex-direction: column;
        align-items: center;
    }
    .cardServies{
        width: 75%;
    }

    .technologiesCard:last-child{
        width: 60%;
    }
    .bannerServices{
        flex-direction: column;
    }
    .textServicesSide{
        width: 90%;
        text-align: center;
        
    }
    .imageServicesSide{
        width: 90%;   
    }
}