.bannerOhbi{
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.9184467732405462) 90%, rgba(41,42,37,1) 100%);
    width: 100%;
    padding-top: 80px;
    height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
}

.phonesBanner{
    height: 100%;
}

.beforeApps img{
    width: 35%;
}


@media screen and (min-width: 766px) {
    .appsOhbi img{
        width: 80%;
    }
}

@media screen and (min-width: 1020px) {
    .appsOhbi img{
        width: 60%;
    }
}


.ohbinformation{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    background-color: #f2f2f2;
    padding-inline: 10%;
    padding-top: 5%;
    padding-bottom: 6%;
    position: relative;
}

.mobileOhbi{
    position: absolute;
    left: 80px;
    width: 320px;
    top: 100px
}

.challengeOhbi{
    text-align: right;
    margin-left: 40%;
  }


.informationContainer{
    width: 60%;
    display:flex;
    flex-direction: column;
    align-items: flex-end
}

.ohbinformation .logo{
    margin-bottom: 5%;
}

.ohbinformation div{
    text-align: end;
}

.steps{
    display: flex;
    flex-direction: row;
    width: 86%;
    margin-inline: 7%;
    justify-content: center;
    color: white;
    column-gap: 5%;
    align-items: center;
    padding-block: 50px;
}

.steps img{
    width: 50%;
}

.container2{
    background-color: #343434;
}

.infoSteps{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: initial;
}

.stepsBySteps h3{
    padding-inline: 2%;
    padding-block: 1%;
    background-color: #D01A27;
    border-radius: 50px;
}

.stepsBySteps{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: justify;
    column-gap: 8%;
}

.iconsValores{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10%;
    text-align: center;
    font-weight: bold;
}

.comfort{
    background-image: url('../../assets/ohbi/backgroundComfort.jpg');
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: space-evenly;
    align-items: center;
    column-gap: 2%;
}

.avion{
    width: 5%;
}

.textComfort{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 40%;
}

.imgComfort{
    display: grid;
    width: 30%;
}

.avion{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.avion img{
    padding-top: 50%;
}

.avion div{
    height: 100%;
    width: 2px;
    text-align: center;
    background-color: white;
    margin-top: -5px;
}

.textComfort{
    text-align: center;
    row-gap: 20px;
}

.imgComfort img{
    width: 100%;
    min-height: 110px;
}

.container{
    padding-top: 2%;
    background-color: white;
    padding-bottom: 3%;
}

.informationContainer{
    width: 60%;
}

.appsOhbi{
    display: flex;
    gap: 20px;
    justify-content: center;
}

@media screen and (min-width: 1300px) {
    .beforeApps img{
        width: 170px;
    }
    
    .appsOhbi img{
        width: 150px;
    }
    
}

@media screen and (max-width: 767px) {
    .bannerOhbi{
        height: 300px;
    }

    .appsOhbi{
        flex-direction: column;
    }

    .appsOhbi img{
        width: 120px;
    }

    .ohbinformation{
        padding-inline: 0;
        padding-bottom: 200px;
        align-items: center;
        justify-content: center;
    }
    .informationContainer{
        width: 80%;
    }
    .mobileOhbi{
        left: 50%;
        top: 390px;
        margin-left: -120px;
    }
    .challengeOhbi{
        text-align: center;
        margin-left: 0;
        margin-top: 300px;
    }

    .challengeOhbi{
        margin-top: 380px;
    }

    .iconsValores div img{
        width: 90%;
    }

    .iconsValores div p{
        font-size: 13px;
    }

    .ohbinformation .logo{
        width: 75%;
    }
    
    .ohbinformation div, .challengeOhbi p{
        text-align: center;
        font-size: 15px;
    }
    .container{
        padding-inline: 10%;
        padding-block: 5px;
        margin-bottom: 0;
    }
    .steps{
        flex-direction: column-reverse;
        font-size: 12px;
    }
    .iconsValores{
        gap:7%;
        padding-inline: 1%;
    }
    .iconsValores div{
        align-items: center;
        justify-content: center;
    }
    .comfort{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .comfort > div{
        width: 100%;
        align-items: center;
        font-size: 13px;
    }
    .comfort:nth-child(3) div{
        margin-inline: 5%;
    }
    .textComfort > div{
        padding-inline: 5%;
    }
    .textComfort{
        margin-bottom: 4%;
    }

    .ohbinformation .logo{
        width:40%;
    }

    .ohbinformation{
        font-size: 15px;
    }
    .avion{
        display: none;
    }
}

@media screen and (max-width: 630px) {
    .appsOhbi img{
        width: 100px;
    }
    .beforeApps img{
        width: 100px;
    }
    
}

@media screen and (max-width: 550px) {
    .phonesBanner{
        height: 80%;
    }
}

@media screen and (max-width: 450px) {
    .appsOhbi img{
        width: 90px;
    }
    .phonesBanner{
        height: 65%;
    }
    .mobileOhbi{
        top: 420px;
        left: 50%;
        width: 270px;
        margin-left: -110px;
    }
    .challengeOhbi{
        margin-top: 295px;
    }
}

@media screen and (max-width: 377px) {
    .phonesBanner{
        height: 55%;
    }
    .challengeOhbi{
        margin-top: 270px;
    }
    .mobileOhbi{
        top: 430px;
    }
}





.iconsValores div img{
    animation: saltar 10s ease-in-out 30s infinite;
}

.iconsValores div:nth-child(1) img{
    animation-delay: 5s;
}
.iconsValores div:nth-child(2) img{
    animation-delay: 7s;
}
.iconsValores div:nth-child(3) img{
    animation-delay:9s;
}
.iconsValores div:nth-child(4) img{
    animation-delay: 11s;
}

@keyframes saltar {
    0%{
        transform: translateY(0);
    }
    10%{
        transform: translateY(-2px);
    }
    50%{
        transform: translateY(10px);
    }
    80%{
        transform: translateY(12px);
    }
}