
.bannerPrincipal{
  height: 400px;
  width: 100%;
  background: radial-gradient(57.99% 70.69% at 44.56% 60.5%, #FDC533 0%, #FF8946 18.75%, #EA3F38 100%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 80px;
}

.bannerPrincipalText{
  color: white;
  max-width: 40%;
  padding-left: 5%;
}


.bannerPrincipal div h1{
  font-family: "Monserrat";
  font-weight: 700;
}

p{
  font-family: "Monserrat";
  font-weight: 400;
}

.App {
  text-align: center;
}

.responsiveMobileAnimation{
  padding-inline-end: 70px;
}

.imagenfondoBanner {
  height: 400px;
}


.imagenfondoBanner img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  -webkit-clip-path: path('M284.80,-27.36 C284.23,165.77 106.47,174.73 -1.88,400.00 L700.00,400.00 L700.00,-0.00 Z');;
  clip-path: path('M284.80,-27.36 C284.23,165.77 106.47,174.73 -1.88,400.00 L700.00,400.00 L700.00,-0.00 Z');
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.containerHome{
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-inline: auto;
  margin-block: 50px;
  padding-inline: 65px;

}

@media screen and (max-width: 767px) {
  .containerHome{
    padding-inline: 0;
  }
  .responsiveMobileAnimation{
    padding-inline-end: 0
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.textoCentradoInitial{
  font-weight: 400;
  font-family: "Monserrat";
  text-align: center;
  padding-inline: 4%; 
  margin-block: 40px;
}

.textoAppHome h2{
  font-family: "Inter";
  font-weight: 400;
}

.clientes{
  background-color: #F2F2F2;
  width: 100%;
  text-align: center; 
  padding-bottom: 95px;
  padding-top: 55px;
}

.clientes div{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 30px;
  justify-content: center;
  align-items: center;
}

.clientes div img{
  text-align: center;
  max-width: 100%;
  margin: auto;
}

.stadistics{
  background: radial-gradient(114% 695.04% at 14.06% 27.5%, #EA3F38 0%, #FDC533 100%);
  height: 145px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  color: white;
  padding-block-end: 50px;
  text-align: center;
  -webkit-clip-path: polygon(50% 0, 50% 0, 100% 0, 100% 60%, 50% 100%, 50% 100%, 0 60%, 0 0);
  clip-path: polygon(50% 0, 50% 0, 100% 0, 100% 60%, 50% 100%, 50% 100%, 0 60%, 0 0);
}

.stadistics div img{
  width: 60px;
  height: 100%;
}

.stadistics div div{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.stadistics div div h1{
  margin: 0;
}

.stadistics div p{
  margin: 0;
}

.stadistics div{
  margin: auto
}

.map-container {
  margin-block: 50px;
  height: auto;
  max-width: 1200px;
  margin-inline:auto;
}

.rsm-geography {
  outline: none;
}


.map {
  width: 100%;
  height: 100%;
}

.country {
  fill: #e4e4e4;
  stroke: #888888;
  stroke-width: 1px;
}

@media screen and (max-width: 1101px) {
  .bannerPrincipalText{
    color: white;
  }
}



.contactSection{
  background-image: url("./assets/principal/pattern.png");
  height: auto;
  padding-inline: 20px;
  padding-block-start: 20px;
  padding-block-end: 50px;
  text-align: center;
}
.contactanos{
  padding: 15px;
}


@media only screen and (max-width: 769px) {
  .bannerPrincipal div h1{
    font-size: 1.3rem
  }
  .bannerPrincipal div p{
    font-size: 0.6rem
  }
  .stadistics{
    grid-template-columns: repeat(2, 1fr);
    height: auto;
    padding-block: 20px;
    row-gap: 30px;
    padding-bottom: 70px;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 57%, 0 0);
    clip-path: polygon(100% 0, 100% 100%, 0 77%, 0 0);
  }
  .clientes{
    padding-bottom: 120px;
  }
}
@media only screen and (max-width: 500px) {
  .imagenfondoBanner{
    background-size: 0%;
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    height: 60%;
  }
  .bannerPrincipalText{
    max-width: 90%;
    height: 40%;
    padding-left: 0;
    color: white;
    margin-inline: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .bannerPrincipal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .imagenfondoBanner img{
    height: 250px;
    -webkit-clip-path: path('M0.00,50.10 C300.00,150.33 349.20,-50.10 500.00,50.10 L500.00,300.00 L0.00,300.00 Z');;
    clip-path: path('M0.00,50.10 C300.00,150.33 349.20,-50.10 500.00,50.10 L500.00,300.00 L0.00,300.00 Z');
  }
}


@media only screen and (max-width: 1000px) {
  .clientes div{
    margin-inline: 20px;
  }
  .clientes div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 5%;
    justify-content: center;
    column-gap: 60px;
  }
}

