.textoCentrado{
  margin-top: 5%;
  text-align: center;
  font-size: 1.1em;
}

.apps{
  display: flex;
  align-items: center;
  margin-block: 10%;
}

.textoAppHome h2{
  margin-block-end: 0;
  margin-block-start: 0;
}

.app{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: 10%;
  justify-content: center;
}

.imgapp{
  margin-left: 14%;
}

.apps:nth-child(even) .textoAppHome{
  padding-left: 10%;
  text-align: center;
}

.apps:nth-child(odd) .textoAppHome{
  padding-right: 10%;
  text-align: center;
}

.textoAppHome > h2 {
  text-transform: uppercase;
  font-weight: unset;
}

.apps:nth-child(even){
  flex-direction: row;
}
.apps:nth-child(odd){
  flex-direction: row-reverse;
}

.apps:nth-child(n) .app .esfera{
  position: absolute;
  z-index: -1;
  width: 380px;
  height: 380px;
  border-radius: 400px;
}
.apps:nth-child(7) .app .esfera{
  background: linear-gradient(180deg, #c81a2b 30%, #1f140f 100%);
}

.apps:nth-child(6) .app .esfera{
  background: linear-gradient(180deg, #ffda31 30%, #fff 100%);
}
.apps:nth-child(5) .app .esfera{
  background: linear-gradient(180deg, #4eecc9 30%, #002b58 100%);
}

.apps:nth-child(4) .app .esfera{
  background: linear-gradient(180deg, #ea3f38 30%, #fff 100%);
}




.button{
  background-color: #000;
  border-radius: 20px;
  padding-block: 10px;
  padding-inline: 25px;
  color: white;
  text-decoration: none;
}

.pMargin{
  margin-bottom: 40px;
}


@media screen and (max-width: 768px) {
  .pMargin{
    margin-bottom: 25px;
  }
  .apps:nth-child(even) .textoAppHome{
    padding-left: 0;
    text-align: center;
    margin-inline: 4%;
  }
  
  .apps:nth-child(odd) .textoAppHome{
    padding-right: 0;
    text-align: center;
    margin-inline: 4%;
  }
}
@media screen and (max-width: 670px) {
  .textoAppHome h2{
    font-size: 18px;
  }
  .textoAppHome p{
    font-size: 14px;
  }
}

@media screen and (max-width: 568px) {
  .pMargin{
    margin-bottom: 10px;
  }
  .button{
    background-color: #000;
    border-radius: 20px;
    padding-block: 7px;
    padding-inline: 25px;
    color: white;
    text-decoration: none;
    font-size: 12px;
  }
  .textoAppHome h2{
    font-size: 18px;
  }
  .textoAppHome p{
    font-size: 12px;
  }
  .apps:nth-child(even) .textoAppHome{
    padding-left: 0;
    text-align: center;
  }
  
  .apps:nth-child(odd) .textoAppHome{
    padding-right: 0;
    text-align: center;
  }
}