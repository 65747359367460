.bannerBarlleno{
    background-image: url("../../assets/barlleno/backgroundHeroBanner.jpg");
    height: 400px;
    background-size: cover;
    padding-top: 80px;
    background-repeat: no-repeat;

}

.dataApp{
    background-color: #EA3F38;
    color: white;
    padding-left: 11%;
    padding-right: 45%;
    padding-block: 5%;
    position: relative;
}


.buttonAppBarlleno:hover{
        background-color: #fff;
        border: 1px solid #f2f2f2;
        color: #000;
        padding-inline: 17px;
        transition: all 500ms;
}


.button2{
    background-color: #f2f2f2;
    text-decoration: none;
    padding-inline: 15px;
    padding-block: 10px;
    border-radius: 10px;
    color: black
}



.tools{
    background-color: transparent;
    padding-left: 11%;
    padding-right: 45%;
    padding-block-start: 2%;
    background-color: #f2f2f2;
    
    padding-bottom: 150px;
    -webkit-clip-path: path("M-400.05,187.98 C944.42,729.25 70.26,-150.45 3600.94,773.61 L3627,-2.45 L0.7,-0.48 Z");
    clip-path: path("M 100 100 L 300 100 L 200 300 Z");
}


.imgTools{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: space-between;
    margin-bottom: 50px;
    align-items: center;
}

.imgTools > a >img{
    width: 50px;
}

.contenedor{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 1000px;
    margin-inline: auto;
    margin-block: 5%;
    column-gap: 2%;
}

.contenedor > img{
    width: 250px;
}

.mobileAppBarlleno{
    position: absolute; 
    margin-top: -255px; 
    width: 300px; 
    right: 40px;
}

.bannerBarlleno div img{
    max-height: 200px;
    
}

.bannerBarlleno div{
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 20%;
}

@media screen and (min-width: 2500px) {
    .tools{
        max-width: 1500px;
    }
}

@media screen and (min-width:1200px) {
    .projects{
        height: 150px;
      }
      .bannerBarlleno img{
        max-width: 2000px;
    }
    .tools{
       -webkit-clip-path: path("M-341.05,370.98 C944.42,729.25 70.26,40.55 3600.94,595.61 L3627,-2.45 L0.7,-0.48 Z");
       clip-path: path("M-341.05,370.98 C944.42,729.25 70.26,40.55 3600.94,595.61 L3627,-2.45 L0.7,-0.48 Z");
    }
}

@media screen and (max-width:1200px) {
    .bannerBarlleno img{
        width: 100%;
    }
    .tools{
        -webkit-clip-path: path("M-302.05,187.98 C944.42,719.25 192.26,-193.45 1600.94,773.61 L1627,-2.45 L0.7,-0.48 Z");
       clip-path: path("M-302.05,187.98 C944.42,719.25 192.26,-193.45 1600.94,773.61 L1627,-2.45 L0.7,-0.48 Z")
    }
}


@media screen and (max-width:767px) {
    .ocultar{
        display: none;
    }
    .challenge{
        margin-inline: 5%;
    }
    .mobileAppBarlleno{
        margin-top: -225px; 
        width: 200px; 
        right: 60px;
    }
    .contenedor > img{
        width: 200px;
    }
    .projectsImg{
        min-height: 100px;
        min-width: 100px;
        width: 100%;
        height: auto;
        gap: 0;
    }
    .projects{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 100%;
    }
    .imgTools{
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .imgTools > a >img{
        width: 40px;
    }
    .dataApp{
        padding-left: 5%;
        
    }
    .tools{
        padding-left: 5%;
        -webkit-clip-path: path("M-69.69,306.55 C159.99,85.38 363.71,459.38 827.82,266.94 L827.25,-72.52 L-129.51,-8.38 Z");
        clip-path: path("M-69.69,306.55 C159.99,85.38 363.71,459.38 827.82,266.94 L827.25,-72.52 L-129.51,-8.38 Z");
    }
    .bannerBarlleno{
        height: 300px;
    }
}

@media screen and (max-width:585px) {
    .button2{
        font-size: 13px;
    }
    .mobileAppBarlleno{
        position: absolute; 
        margin-top: -185px; 
        width: 170px;
        right: 0;
    }
    .imgTools{
        margin-bottom: 20px;
        column-gap: 70px;
    }
    .imgTools > a >img{
        width: 30px;
    }
    .challenge p, .dataApp p{
        font-size: 13px;
    }
    .bannerBarlleno{
        height: 200px;
    }
}


@media screen and (max-width:390px) {
    .dataApp{
        padding-block-start: 3%;
        padding-block-end: 30px;
    }
    .mobileAppBarlleno{
        position: absolute; 
        margin-top: -150px; 
        width: 150px;
        right: 0;
    }
    .imgTools > a >img{
        width: 30px;
    }
    .challenge p, .dataApp p{
        font-size: 13px;
    }

    .imgTools{
        column-gap: 50px;
    }
}