.projects{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100px;
}

.projectIndividual{
    width: 100%;
    display: block;
    height: 150px;
}

.projectsImg{
    min-height: 100px;
    min-width: calc(100%-20px);
    height: 100%;
    width: 100%;
    max-width: 1000px;
    gap: 0;
    
}

.splitUsProjectImg{
    width: 70%;
    height: 50%;
}

.premierToolsProjectImg{
    width: 35%;
    height: 70%;
}

@media screen and (min-width: 2000px) {
    .premierToolsProjectImg{
        width: 30%;
    }
}

@media screen and (max-width: 460px) {
    .splitUsProjectImg{
        width: 70%;
    }
    
}


@media screen and (max-width:767px) {
    .projectsImg{
        min-height: 100px;
        min-width: 100px;
        width: 100%;
        height: auto;
        gap: 0;
    }
    .projects{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 100%;
    }
    .projectIndividual{
        height: 100px;
    }
    .premierToolsProjectImg{
        width: 30%;
    }
}