.bannerJobs{
    background-image: url("../../assets/jobs/meeting.png");
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 80px;
    width: 100%;
}

.textBannerJobs{
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 20px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
}


.jobsContainer{
    background-color: #272727;
    display: flex;
    padding-block: 30px;
}


.cardJob{
    background-color: white;
    border-radius: 20px;
    width: 80%;
    margin: auto;
    text-align: center;
    padding-inline: 50px;
    padding-block: 30px;
}

.cardJob h2{
    color: #f16e36;
}

.inputJobs{
    border-radius: 5px;
    width: 90%;
    border-style: none;
    border: 1px solid gray;
    padding-block: 5px;
    margin-bottom: 10px;
}

.inputJobs:focus-visible{
    border: 2px solid #f16e36;
    outline: none;
    color: #f16e36;
    font-size: 19px;
}



.primaryButtonJobs{
    margin-left: 20px;
    background-color: #f16e36;
    width: 25%;
    border-style: none;
    border-radius: 5px;
    color: white;
    padding-block: 7px;
    padding-inline: 5px;
    min-width: 60px;
    line-break: auto;
    cursor: pointer;
}

.rowJob td{
    padding-block: 20px;
}

.rowJob td:nth-child(2){
    color: #777775;
}

.rowJob td:nth-child(3){
    color: #f16e36;
}

.rowJob{
    border-bottom: 1px solid gray;
}

.jobsTable{
    width: 100%;
    border-spacing: 0;
}

.jobsTable td{
    border-bottom: 2px solid #ddd;
}



@media screen and (max-width: 600px) {
    .hiddenJob{
        display: none;
    }
    .cardJob{
        padding-inline: 20px;
    }

    .bannerJobs{
        height: 250px;
    }
}