@font-face {
    font-family: "ForcedSquare";
    src:url('../../fonts/FORCEDSQUARE.ttf');
  }

  @font-face {
    font-family: "Monserrat";
    src:url('../../fonts/Monserrat.ttf');
  }

  @font-face {
    font-family: "Inter";
    src:url('../../fonts/Inter.ttf');
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: Helvetica, sans-serif;
    background-color: #fff;
  }
  
  .logospan,.letra{
    font-family: "ForcedSquare";
    font-size: 45px;
  }
  
  a {
    color: #000;
  }
  
  .header {
    background-color: #fff;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
    position: fixed;
    width: 100%;
    z-index: 100000;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  
  .header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
  }
  
  .header li a {
    display: block;
    padding: 20px 20px;
    text-decoration: none;
  }
  
  .header li a:hover,
  .header .menu-btn:hover {
    background-color: #fff;
    border-top: 6px solid transparent;
    border-image: radial-gradient(114% 695.04% at 14.06% 27.5%, #f48735 0%, #ec4e38 40%) 10;
  }
  
  .header .logo {
    display: block;
    float: left;
    font-size: 2em;
    padding-inline-start: 20px;
    padding-block: 20px;
    text-decoration: none;
  }
  
  .header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
  }
  
  .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 38px 20px;
    position: relative;
    user-select: none;
  }
  
  .header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 24px;
  }
  
  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
  }
  
  .header .menu-icon .navicon:before {
    top: 5px;
  }
  
  .header .menu-icon .navicon:after {
    top: -5px;
  }
  
  /* menu btn */
  
  .header .menu-btn {
    display: none;
  }
  
  .header .menu-btn:checked ~ .menu {
    max-height: 240px;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }

@media screen and (max-width: 769px) {
  .header {
    display: block;
  }
}

@media screen and (max-width: 340px) {
  .header .logo{
    font-size: 30px;
  }
  .logospan, .letra{
    font-size: 40px;
  }

  .header .menu-icon{
    padding-inline: 5px;
  }
}
  