.containerContactUs{
    background-color: #FF6633;
    padding-block: 130px;
    text-align: center;
}

.cardContactUs{
    max-width: 1000px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,1);
    margin-bottom: 50px;
    width: 80%;
    display: flex;
    flex-direction: row;
    padding: 30px;
    margin-inline: auto;
    justify-content: space-between;
    margin-inline: auto;
    align-items: center;
    text-align: center;
    gap: 2%;
}

.cardContactUs img{
    width: 48%;
}



.section1{
    padding: 15px;
    border: 1px solid #afafaf;
    margin-bottom: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}

.section2{
    padding: 15px;
    background-color: black;
    border: 1px solid #afafaf;
    border-radius: 10px;
    color: white;
}


.formContacUs{
    display: flex;
    flex-direction: column;
    text-align: left;
    row-gap: 20px;
    width: 100%;
}

.formContacUs label{
    display: flex;
    flex-direction: column;
    text-align: left;
    row-gap: 20px;
    width: 100%;
}

.formContacUs label input, .formContacUs label textarea{
    border-radius: 15px;
    border-width: 1px;
    border-color: #afafaf;
    padding-left: 10px;
    padding-block: 12px;
    box-sizing: border-box;
}

.sendButtonContact{
    background-color: #FF6633;
    border-radius: 15px;
    border: 2px;
    padding-block: 10px;
    color: white;
    cursor: pointer;
}

.sendButtonContact:hover{
    background-color: #ff7e53;
}

@media screen and (min-width: 600px) {
    .textSideContacUs{
        width: 45%;
        margin-block: 20px;
    }    
}


@media screen and (max-width: 800px) {
    .cardContactUs img{
        width: 40%;
    }
}

@media screen and (max-width: 600px){
    .cardContactUs{
        flex-direction: column;
        width: 70%;
    }
    .cardContactUs:first-child{
        flex-direction: column-reverse;
        width: 70%;
    }
    .cardContactUs img{
        width: 80%;
    }
}

@media screen and (max-width: 380px) {
    .section1{
        line-break: auto;
    }
    .cardContactUs .section1{
        flex-direction: column;
        align-items: center;
        row-gap: 15px;
    }
}


