.bannerPremierTools{
    background-color: #003366;
    background: url("../../assets/premierTools/patternPremierTools.png");
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 80px;
    height: 400px;
    justify-content: space-between;
}

.bannerPremierTools div:nth-child(1) {
    width: 50%;
    text-align: center;
    height: 60%;
}

.bannerPremierTools div:nth-child(1) img{
    object-fit: contain;
    width: 100%;
    height: 100%;
    padding: 20px
}

.bannerPremierTools div:nth-child(2) {
    width: 45%;
    text-align: right;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.bannerPremierTools div:nth-child(2) img:nth-child(1){
    height: 97%;
}


.bannerPremierTools div:nth-child(2) img:nth-child(2){
    height: 80%;
}

.dataAppPremier{    
    background-color: #003366;
    color: white;
    padding-left: 11%;
    padding-right: 45%;
    padding-block: 5%;
    position: relative;

}

.mobileAppPremierTools{
    position: absolute; 
    top: 100px;
    width: 350px;
    right: 45px;
    z-index: 20
}

.toolsPremier{
    background-color: transparent;
    padding-right: 38%;
    padding-block: 2%;
    max-width: 1000px;
    text-align: center;
}

.toolsPremier img{
    width: 800px;
}

.responsiveMobileAnimation{
    top: 0;
    position: absolute;
    right: 0;
    z-index: 200
}



@media screen and (max-width:1100px){
    .toolsPremier img{
        width: 480px;
    }
    .mobileAppPremierTools{
        position: absolute; 
        top: 50px;
        width: 280px;
        right: 45px;
    }

}

@media screen and (max-width: 767px){
    .bannerPremierTools div:nth-child(2) img:nth-child(2){
        display: none;
    }
    .bannerPremierTools div:nth-child(2){
        width: 35%;
    }
    .bannerPremierTools div:nth-child(3){
        width: 100%;
        text-align: center;
        display: block;
        position: absolute;
    }

    .toolsPremier{
        padding-right: 0;
        margin-top: 290px;
    }
    .dataAppPremier{
        padding-right: 0;
        padding-left: 0;
        padding-inline: 5%;
        text-align: center;
        padding-bottom: 230px;
    }

    .dataAppPremier div{
        text-align: center;
        width: 100%;
    }
    .mobileAppPremierTools{
        position:absolute;
        left: 50%;
        top: 0;
        margin-left: -120px;
    }

    .toolsPremier img{
        width: 100%;
    }
    .mobileAppPremierTools{
        width: 270px;
    }
    .responsiveMobileAnimation{
        margin-top: 40px;
        top: 470px;
    }
}

@media screen and (max-width: 620px) {
    .bannerPremierTools div:nth-child(1) {
        width: 45%;
    }
    .bannerPremierTools div:nth-child(2) {
        width: 45%;
    }
    .bannerPremierTools div:nth-child(2) img:nth-child(1){
        height: 90%;
    }
    .responsiveMobileAnimation{
        margin-top: 40px;
        top: 500px;
    }
}

@media screen and (max-width: 570px) {
    .responsiveMobileAnimation{
        margin-top: 60px;
        top: 550px;
    }
}

@media screen and (max-width: 485px) {
    .responsiveMobileAnimation{
        margin-top: 60px;
        top: 580px;
    }
}

@media screen and (max-width: 415px) {
    .responsiveMobileAnimation{
        margin-top: 60px;
        top: 660px;
    }
}

@media screen and (max-width: 355px) {
    .responsiveMobileAnimation{
        margin-top: 60px;
        top: 710px;
    }
}

@media screen and (max-width: 440px) {
    .bannerPremierTools div:nth-child(1) {
        width: 45%;
    }
    .bannerPremierTools div:nth-child(2) {
        width: 45%;
        height: 80%;
    }
    .bannerPremierTools div:nth-child(2) img:nth-child(1){
        width: 100%;
    }
    .bannerPremierTools div:nth-child(2) img:nth-child(1){
        height: 90%;
    }
}

@media screen and (max-width: 440px) {
    .mobileAppPremierTools{
        top: 65px;
        left: 50%;
        width: 230px;
        margin-left: -100px;
    }
    .toolsPremier{
        margin-top: 240px;
    }
}



@media screen and (min-width: 769px){
    .bannerPremierTools div:nth-child(3){
        display: none;
    }
}
