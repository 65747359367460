
.footer-container {
    background-color: #151515;
    padding: 2rem 0;
}
.footer-heading:nth-child(2){
  width: 265px;
}

.paragrahpWithoutMargin{
  margin-block: 0;
}


.footer-container h3{
  color: white;
  text-align: center;
  font-size: 16px;
}
  
.footer {
    width: 90%;
    color: #fff;
    display: flex;
    justify-content: space-evenly;
    margin: auto;
  }
  
  .footer-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .footer-heading h2 {
    font-size: 34px;
    margin-bottom: 2rem;
    margin-block-start: 0;
    margin-inline-start: 0;
  }
  
  .footer-heading a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  .icons{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .button:hover{
    background-color: transparent;
    color: #000;
    border: 1px solid #000;
    cursor: pointer;
    padding-inline: 19px;
    transition: all 500ms;
  }

  .footer-heading a:hover {
    color: #bfbfbf;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  
  .footer-heading > .logo{
    font-size: 35px;
    letter-spacing: 4px;
    text-align: center;
  }
  
  .footer-heading > .logo >.logospan{
    font-size: 49px;
  }
  
  .contact{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 15px;
  }
  
  .linea{
    border-left: 1px solid white;
  }

  .contact p{
    text-align: left;
  }

  
  .responsiveMobile{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .responsiveMobile div:nth-child(1){
    margin-right: 12px;
  }
  .responsiveMobile div:nth-child(2){
    padding-left: 9px;
  }

  
  .projects{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100px;
  }
  .projectsImg{
    min-height: 100px;
    min-width: calc(100%-20px);
    height: 100%;
    width: 100%;
    max-width: 1000px;
    gap: 0;
    
  }
  
  /* RESPONSIVE */ 
  
  @media screen and (min-width: 767px) {
    .header li {
      float: left;
    }
    .header li a {
      padding: 30px 16px;
    }
    .header .menu {
      clear: none;
      float: right;
      max-height: none;
    }
    .header .menu-icon {
      display: none;
    }
    .mobile{
      display: none;
    }
    .container{
      padding-inline: 10%;
    }
    .footer-heading{
      padding-left: 20px;
    }

  }

  @media screen and (max-width: 396px) {
    .contact p{
      font-size: 12px;
    }
    .footer-heading a{
      font-size: 12px;
    }
    .footer-container h3{
      font-size: 13px;
    }
    .footer-heading:nth-child(2){
      width: 180px;
    }
  }
  
  @media screen and (max-width: 767px) {
    .footer:nth-child(1) .footer-heading{
      flex-direction: column;
    }
    .footer-container {
      border-top: 0;
      text-align: left;
    }
    .responsiveMobile{
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: space-around;
      width: 100%;
    }
    .responsiveMobile:nth-child(2) .footer-heading{
      margin-right: 0;
    }
    .footer{
      width: 95%;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
    }
    .app{
      display: none;
    }
    .container{
      margin-inline: 0%;
      margin-bottom: 10%;
    }
    .apps{
      margin-block: 0;
    }
    .apps:nth-child(even) .textoApp{
      padding-left: 0;
    }
    
    .apps:nth-child(odd) .textoApp{
      padding-right: 0;
    }
    .textoCentrado{
      margin-block: 2%;
    }
    .mobile{
      display: flex;
      align-items: center;
      width: 80px;
      height: 210px;
    }
    .mobileImg{
      min-height: 200px;
      width: 80px;
      height: 100%;
    }
    .apps:nth-child(odd){
      flex-direction: row;
    }
    .textoApp{
      margin-inline: 5%;
    }
    .textoCentrado{
      margin-inline: 3%;
    }
    .textoCentrado > p{
      font-size: 15px;
    }
    .footer-heading h2{
      font-size: 20px;
      margin-bottom: 10px;
    }
    .textoApp > p {
      font-size: 13px;
    }
    .footer-heading{
      flex-direction: column;
    
    }
    .linea{
      border-left:0;
      margin-right: 0;
    }
    .responsiveMobile div:nth-child(2){
      padding-left: 0;
    }
  }