.darkBG {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    z-index: 100000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
  }
  
  .centered {
    width: 85%;
    height: 80%;
    position: fixed;
    z-index: 100000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .modal {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background: white;
    color: white;
    z-index: 10;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
  
  .modalHeader {
    padding-top: 20px;
    height: 70px;
    background: white;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  
  .heading {
    margin: 0;
    padding: 10px;
    color: #2c3e50;
    text-align: center;
  }
  
  .modalContent {
    padding: 10px;
    font-size: 14px;
    color: #2c3e50;
    text-align: center;
  }
  
  .modalActions {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .actionsContainer {
    padding-block-start: 10px;
    padding-block-end: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .closeBtn {
    cursor: pointer;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 15px;
    border: none;
    font-size: 18px;
    color: white;
    background: #FF6633;
    transition: all 0.25s ease;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    position: absolute;
    right: 0;
    top: 0;
    align-self: flex-end;
    margin-top: -7px;
    margin-right: -7px;
  }
  
  .closeBtn:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    transform: translate(-4px, 4px);
  }
  
  .deleteBtn {
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 12px;
    font-size: 0.8rem;
    border: none;
    color: #fff;
    background: #FF6633;
    transition: all 0.25s ease;
  }
  
  .deleteBtn:hover {
    box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
    transform: translateY(-5px);
    background: #FF6633;
  }
  
  .cancelBtn {
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 12px;
    font-size: 0.8rem;
    border: none;
    color: #2c3e50;
    background: #fcfcfc;
    transition: all 0.25s ease;
  }
  
  .cancelBtn:hover {
    box-shadow: none;
    transform: none;
    background: whitesmoke;
  }

  .inputModalJobs{
    border-radius: 5px;
    width: 70%;
    border-style: none;
    border: 1px solid gray;
    padding-block: 5px;
    margin-bottom: 10px;
  }

  .inputModalJobs:focus{
    border-style: none;
  }















  .dropzone {
    margin-top: 30px;
    margin-bottom: 80px;
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    width: 70%;
    margin-inline: auto;
  }
  
  .dropzone_label {
    font-size: 18px;
    color: #999;
    cursor: pointer;
  }
  
  .dropzone_input {
    display: none;
  }


  .insideNameFile{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }




  
  .alertMessage{
    position: fixed;
    top: 90px;
    right: 5px;
    background-color: rgba(255, 255, 255,0.9);
    color: #FF6633;
    padding: 20px;
    border-radius: 10px;
    border-top: 5px solid #afafaf;
}

.alertContactUs{
    position: absolute;
    top: 90px;
    right: 5px;
    color: white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    z-index: 19999;
}

.error{
    background-color: #d85261;
}

.success{
    background-color: #a6d288;
    background-color: #a6d288;
    width: 156px;
    float: right;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    right: 12px;
}